
// import Swiper from 'swiper/bundle';
import * as d3 from 'd3';
import * as topojson from "topojson-client";

// import styles bundle
// import 'swiper/css/bundle';

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
  // import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);


jQuery(document).ready(function($) {

	// SWIPER SLIDERS

	//header homepage swiper
	var homepageBanner = document.getElementsByClassName("homepageBanner");

	if(homepageBanner.length > 0){

		const headerSwiper = new Swiper("#headerSwiper.slider", {
			slidesPerView: 1,
			autoplay: true,
			effect: 'fade',
			loop: true,
			delay: 2000,
			speed: 1000,
		});

		headerSwiper.on('slideChange', function () {
			const index = this.realIndex + 1;
			setTimeout(function(){
				headerSwiperText.slideTo(index);
			}, 200);
			
		});

		const headerSwiperText = new Swiper("#headerSwiperText.slider", {
			slidesPerView: 1,
			effect: 'fade',
			loop: true,
			allowTouchMove: false,
			fadeEffect: {
				crossFade: true
			},
			delay: 2000,
			speed: 1000,
		});
	}

	
	var summarywrapper = document.getElementsByClassName("summary-wrapper");

	if(summarywrapper.length > 0){

		const summary = document.querySelectorAll('.summary-wrapper');
		var i = 0;

		var summaryFades = {};

		for( i=0; i< summary.length; i++ ) {

			
			summary[i].classList.add('summary-wrapper-' + i); //slider
			summary[i].previousElementSibling.dataset.index = i;		
			
			summaryFades[i] = new Swiper('.summary-wrapper-' + i, {
				speed: 1000,
				effect: 'fade',
				allowTouchMove: false,
				fadeEffect: {
					crossFade: true
				},
				slidesPerView: 1,
				direction: 'horizontal',
				autoplay: false,
				loop: false,
			});


			// foreach summary-link
			$('section.summary').addClass('summary-' + i);

			$('body').on('click', 'section.summary-'+ i +' .summary-link', function(event){
				event.preventDefault();
				var sliderIndex  = $(this).parent().data('index');

				$('section.summary-'+ sliderIndex +' .summary-link').each(function() {
					$(this).removeClass('selected')
				});

				$(this).addClass('selected');

				var index = $(this).data('index');
				
				summaryFades[sliderIndex].slideTo(index);
			});

		}

	}



	var quotewrapper = document.getElementsByClassName("quotes-wrapper");

	if(quotewrapper.length > 0){

		//quotes carousel
		const quotes = document.querySelectorAll('.quotes-wrapper');
		var i = 0;

		for( i=0; i< quotes.length; i++ ) {

			var first = quotes[i].nextElementSibling;


			quotes[i].classList.add('quotes-wrapper-' + i); //slider
			first.classList.add('quotes-pagination-' + i); //pagination

			
			var quotesSlides = new Swiper('.quotes-wrapper-' + i, {
				speed: 1000,
				direction: 'horizontal',
				effect: 'fade',
				allowTouchMove: false,
				fadeEffect: {
					crossFade: true
				},
				autoplay: true,
				loop: true,
				slidesPerView: 1,
				pagination: {
					el: '.quotes-pagination-' + i,
					type: 'bullets',
					clickable: true,
				},

			});
			
		}
	}


	$('body').on('click', '.btnNext', function(event){
		event.preventDefault();
		var no = $(this).attr("data-no") 
		$('.nav-tabs > #nav-tab-'+no).trigger('click');
	});



	//menu
	$('body').on('click','.mobileMenu',function(){
		$('.menu .content').show();
		$('body').toggleClass('mobileOpen');
		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');

		$(this).toggleClass('open');
	});


	//close menu for desktop if resize is bigger than breakpoint
	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 1200) {
			
			$('.menu .content.open').hide();

			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
			
		}

		if (win.width() < 520){
			var div = win.width() - 120;
			var height = div

			$(".europeMap").css({"height": height})
			// var mySVG = document.getElementById('euroMapSVG');
			// mySVG.setAttribute("viewBox", "0 0 1200 1100");
		}

		if (win.width() < 991 && win.width() > 519){
			var div = win.width() - 100;

			var height = div

			$(".europeMap").css({"height": height})
			// var mySVG = document.getElementById('euroMapSVG');
			// mySVG.setAttribute("viewBox", "0 0 1200 1100");
		}
		if (win.width() > 991 && win.width() < 1200){
			$(".europeMap").css({"height": '630px'})
		}

		if (win.width() > 1199){
			$(".europeMap").css({"height": '700px'})
		}
	});

	var win = $(window);

	if (win.width() < 520){
		var div = win.width() - 100;
		var height = div

		// var mySVG = document.getElementById('euroMapSVG');
		// mySVG.setAttribute("viewBox", "0 0 1200 1100");
		$(".europeMap").css({"height": height})
	}

	if (win.width() < 991 && win.width() > 519){
		var div = win.width() - 200;
		var height = div
		// var mySVG = document.getElementById('euroMapSVG');
		// mySVG.setAttribute("viewBox", "0 0 1200 1100");
		$(".europeMap").css({"height": height})
	}
	if (win.width() > 991 && win.width() < 1200){
		$(".europeMap").css({"height": '630px'})
	}

	if (win.width() > 1199){
		$(".europeMap").css({"height": '700px'})
	}



	//map filter selection
	$('body').on('click', '.filterSelection', function(event){
		event.preventDefault();
		$('.filterSelection').removeClass('active');
		$(this).addClass('active');

		var name = $(this).data('marker');

		$('.mapMarker').removeClass('show');

		$('.'+name).addClass('show');

		if($('ul.countries').length){
			if(name == 'purple_marker'){
				$('li.current').hide();
				$('li.previous').show();
			}
			if(name == 'red_marker'){
				$('li.previous').hide();
				$('li.current').show();
			}
		}
	});


	$('body').on('click', '.tessellateConsent', function(event){
		event.preventDefault();
		$('.cs-info-sticky-button').click();
	});




	var videoSection = document.getElementsByClassName("video_and_text");

	if(videoSection.length > 0){

		autoPlayYouTubeModal();

		function autoPlayYouTubeModal() {
			var trigger = $('.openVideoModal');
			trigger.click(function () {
				
				var theModal = $(this).data("bs-target");
				var videoSRC = $(this).data("src");
				
				var videoSRCauto = videoSRC + "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
				$(theModal + ' iframe').attr('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
				$(theModal + ' iframe').attr('src', videoSRCauto);
				
				$(theModal).on('hidden.bs.modal', function (e) {
					$(theModal + ' iframe').attr('src', '');
				});

			});

		};


		autoPlayYouTubeModallink();


		function autoPlayYouTubeModallink() {
			var trigger2 = $('.playLink');
			
			trigger2.click(function () {

				
				var theModal2 = $(this).data("bs-target");
				var videoSRC2 = $(this).data("src");
				
				var videoSRCauto2 = videoSRC2 + "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
				$(theModal2 + ' iframe').attr('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
				$(theModal2 + ' iframe').attr('src', videoSRCauto2);
				
				$(theModal2).on('hidden.bs.modal', function (e) {
					$(theModal2 + ' iframe').attr('src', '');
				});

			});

		};
	}
	
});


var elementExists = document.getElementsByClassName("europeMap");

if(elementExists.length > 0){

	//svg height and width
	const width = 1200;
	const height = 1100;

	//create svg to div with width and height atts
	const svg = d3.select('.europeMap').append('svg').attr("viewBox", "0 0 " + width + " " + height ).attr("id", "euroMapSVG").attr("preserveAspectRatio", "xMinYMin");


	// .attr('width', width).attr('height', height);

	//use d3 library to create projections
	const projection = d3.geoMercator().scale(300)
	.rotate([-11, 0]).translate([150, 422]);
	const path = d3.geoPath(projection);

	//create g tags in svg
	const g = svg.append('g');

	// get topojson and convert to geojson
	d3.json('/wp-content/themes/tessellate/assets/json/map.json')
	.then(data => {

		const countries = topojson.feature(data, data.objects.countries);

		const ids = data.objects.countries.geometries

		const idArray = []

		//create array of IDS from topojson
		ids.forEach(obj => {
			Object.entries(obj).forEach(([key, value]) => {

				if(key == 'id'){
					idArray.push(value)
				}
			});
		});

		var red_markers = '';
		var purple_markers = '';

		// array of red markers
		d3.json('/wp-json/tessellate/mapmarkers')
		.then(data => {
			
			red_markers = data.red.red;
			purple_markers = data.purple.purple;

			//append countries to path
			g.selectAll('path').data(countries.features).enter().append('path').attr('class', 'country').attr('d', path);

			//assign id to country to match topojson
			svg.selectAll('path').each(function (d, i) { this.classList.add(idArray[i]) });


			//markers
			if (data.red.length != 0){
				//red markers
				const redInfoWindowOld = svg.selectAll(".redmarker")
				.data(red_markers)
				.join('g')
				.attr('class', 'infoGroupRed')
				.append("image")
				.attr('data-title', (data) => {
					return data.title;
				})
				.attr('data-link', (data) => {
					return data.link;
				})
				.attr('class', 'red_marker mapMarker show')
				.attr("xlink:href", '/wp-content/themes/tessellate/assets/images/redmarker.png')
				.attr("transform", (data) => {
					let position = projection([data.long,data.lat]);
					return `translate(${position[0]-14}, ${position[1]-34})`;
				});

				const redInfoWindow = svg.selectAll(".infoGroupRed")
					.data(red_markers)
					.join('g')
					.append('g')
					.attr('class', 'infoWindow')
					.attr("transform", (data) => {
						let position = projection([data.long,data.lat]);
						return `translate(${position[0]}, ${position[1]-24})`;
					});

				redInfoWindow.append("rect")
					.attr('x', -10)
					.attr('y', -24)
					.attr('width', 87)
					.attr('height', 40)
					.attr('fill', '#EF5D5E')
					

				redInfoWindow.append("text")
					.html(({title,link}) => '<a href="'+link+'">'+title+'</a>');
			}




			if (data.purple.length != 0){
				//purplw markers
				svg.selectAll(".purplemarker")
				.data(purple_markers)
				.join('g')
				.attr('class', 'infoGroupPurple')
				.append("image")
				.attr('data-title', (data) => {
					return data.title;
				})
				.attr('data-link', (data) => {
					return data.link;
				})
				.attr('class', 'purple_marker mapMarker')
				.attr("xlink:href", '/wp-content/themes/tessellate/assets/images/purplemarker.png')
				.attr("transform", (data) => {
					let position = projection([data.long,data.lat]);
					return `translate(${position[0]-14}, ${position[1]-34})`;
				});

				const purpleInfoWindow = svg.selectAll(".infoGroupPurple")
					.data(purple_markers)
					.join('g')
					.append('g')
					.attr('class', 'infoWindow')
					.attr("transform", (data) => {
						let position = projection([data.long,data.lat]);
						return `translate(${position[0]}, ${position[1]-24})`;
					});

				purpleInfoWindow.append("rect")
					.attr('x', -10)
					.attr('y', -24)
					.attr('width', 87)
					.attr('height', 40)
					.attr('fill', '#46427A')

				purpleInfoWindow.append("text")
					.html(({title,link}) => '<a href="'+link+'">'+title+'</a>');
			}

			



			//info window sizes for map
			document.querySelectorAll('text').forEach(function(node) {
				// Do whatever you want with the node object.
				const bbox = node.getBBox(); 
				const {  
					width,
				} = bbox;  
		
				const rect = node.previousElementSibling

				rect.style.width = width + 20;
				rect.style.transform = 'translate(-'+width / 2+'px)';

				node.style.transform = 'translate(-'+width / 2+'px)';
			});

			
		})
	});
}
	
